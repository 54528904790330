import React from "react";
import styled from 'styled-components'
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
// @ts-expect-error false positive image import failure
import Diagram from '../../assets/image/heros/diagram.png'
// @ts-expect-error false positive image import failure
import imgL1FeatureCurve from "../../assets/image/svg/l1-curve-feature.svg";

const FaqCard = styled(Box)`
  padding-left: 55px;
  position: relative;
  &::before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.light};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`;

const FaqTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.75px;
  margin-bottom: 14px;
  line-height: 2rem;
`;

const DiagramImage = styled.img`
  width: 80%;
  margin-bottom: 50px;
  margin-top: 20px;
`

const ShapeBottomRight = styled(Box)`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  img {
    min-width: 100%;
  }
`;

const DiagramContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Feature1 = () => (
  <>
    <Section position="relative">
      <ShapeBottomRight>
        <img src={imgL1FeatureCurve} alt="" className="img-fluid" />
      </ShapeBottomRight>
      <Container>
        <DiagramContainer>
          <DiagramImage margin-top={4} src={Diagram} />
        </DiagramContainer>
        <Title textColor="dark">How it works.</Title>
        <Text color="dark" mb={4}>
          Designed for Town &amp; City Centres, Shopping malls and retail parks, our systems connect retailers and licensed premises with centre security, local police and CCTV control.
          Using the latest Smart Radio and our secure information sharing app ‘alert!’ we enable businesses to share incidents instantly as a group to promote safer spaces for customers and staff alike.
        </Text>
      </Container>
    </Section>
  </>
);

export const Feature2 = () => (
  <>
    <Section bg="light">
      <Container>
        <Title mb="4">In partnership.</Title>
        <Row>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Train</FaqTitle>
                <Text fontSize="18px" lineHeight="26px" opacity={0.7}>
                  Face-to-Face and group training events, as well as access to
                  our online video training modules, ensures staff are always
                  trained.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Grow</FaqTitle>
                <Text fontSize="18px" lineHeight="26px" opacity={0.7}>
                  More users are better for everyone, our travelling
                  ambassadors and dual branded marketing resources are
                  proven to grow your schemes membership.
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Earn</FaqTitle>
                <Text fontSize="18px" lineHeight="26px" opacity={0.7}>
                  We undertake a full facilities management role to bill, not only
                  our radios, but your partnership fee or radio income too
                </Text>
              </div>
            </FaqCard>
          </Col>
          <Col lg="6" className="mb-5">
            <FaqCard>
              <div>
                <FaqTitle>Support</FaqTitle>
                <Text fontSize="18px" lineHeight="26px" opacity={0.7}>
                  Our 24/7/365 helpdesk is always at hand to identify a
                  problem, with next day service exchange and prepaid return
                  packing keep our members operational.
                </Text>
              </div>
            </FaqCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);
