import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
} from "../../components/Core";

import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgL2HeroOval1 from "../../assets/image/png/l2-hero-oval1.png";
// @ts-expect-error false positive image import failure
import imgL2HeroOval2 from "../../assets/image/png/l2-hero-oval2.png";
// @ts-expect-error false positive image import failure
import imgL2HeroBg from "../../assets/image/heros/townlink.jpeg";
import pdfBrochure from "../../assets/downloads/ShopSafe-ShopWatch-and-TownLink-Brochure.pdf"

const SectionStyled = styled(Section)`
  background: url(${imgL2HeroBg}) no-repeat;
  background-size: cover;
  background-position: bottom left;
  padding-bottom: 150px;
  overflow: hidden;
  position: relative;

  @media ${device.lg} {
    background-position: bottom
    ;
  }
`;

interface ThemeProps {
  theme: DefaultTheme;
}

const Overlay = styled.div`
    background-color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: .3;
`

const ImageTop = styled(Box)<ThemeProps>`
  border: 10px solid transparent;
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: -11%;
  left: -13%;
  border-radius: 50%;
  overflow: hidden;

  @media ${device.lg} {
    left: 2%;
  }
`;

const ImageBottom = styled(Box)`
  position: absolute;
  right: -13%;
  top: 54%;

  @media ${device.lg} {
    right: -23%;
  }
  @media ${device.xl} {
    right: -28%;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled>
        <Overlay />
        <div className="pt-5"></div>
        <Container>
          <Row>
            <Col
              md="9"
              lg="7"
              className="order-lg-1"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                <Title variant="hero" textColor="light">
                  ShopWatch &amp; TownLink Radio
                </Title>
                <a href={pdfBrochure} download>
                  <Button>Download our brochure</Button>
                </a>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
