import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device, ColorTypes } from "../../utils";

// @ts-expect-error false positive image import failure
import imgMobile from "../../assets/image/radios/pnc-550-alert.png";
// @ts-expect-error false positive image import failure
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
// @ts-expect-error false positive image import failure
import iconLayout from "../../assets/image/png/icon-layot-25.png";

interface ContentCardProps {
  className?: string;
  iconName?: string;
  iconImage?: string;
  title?: string;
  mb?: number;
  color?: keyof ColorTypes;
  children: React.ReactNode;
}

interface ThemeProps {
  theme: DefaultTheme;
  color: keyof ColorTypes;
  children: React.ReactNode;
}

const ContentCard: React.FC<ContentCardProps> = ({
  color = "primary",
  className,
  iconName,
  iconImage,
  title,
  children,
  ...rest
}) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box>
      <Title textColor="primary" variant="card" fontSize="24px" mb={1}>
        {title}
      </Title>
      <Text fontSize="18px" lineHeight="28px">
        {children}
      </Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
  max-width: 70%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    margin-left: 50px;
  }
`;

const Content2 = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section>
      <Container>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-5"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="12">
            <Box className="pt-5 pt-lg-0">
              <Title>Simple &amp; Secure Information Sharing.</Title>
              <Box mt={4} pt={3}>
                <ContentCard
                  className="test"
                  title="Report Incidents"
                  iconImage={icon3dModel}
                  mb={4}
                >
                  Report crimes and incidents together &amp; add intel to
                  existing events.
                </ContentCard>
                <ContentCard
                  className="test"
                  title="Explore Offenders"
                  iconImage={iconLayout}
                  mb={4}
                >
                  Gather a database of local offenders &amp; build knowledge in
                  the community. Add exclusions to offenders for others to
                  follow.
                </ContentCard>
                <ContentCard
                  className="test"
                  title="Group Messaging"
                  iconImage={iconLayout}
                  mb={4}
                >
                  Instant group collaboration and communication through secure
                  messaging - share real time information and images.
                </ContentCard>
                <ContentCard
                  className="test"
                  title="Access Anywhere"
                  iconImage={iconLayout}
                  mb={4}
                >
                  Laptop, tablet or phone - Alert! was designed to be used on
                  all devices wherever you are.
                </ContentCard>
              </Box>
              <Link to="/alert">
                <Button>Find Out More</Button>
              </Link>
            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
