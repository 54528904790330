import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

const SectionStyled = styled(Section)``;

const Fact = () => (
  <>
    {/* <!-- Fact section 1 --> */}
    <SectionStyled py={5} pb={3} bg="#F7F7FB">
      <Container>
        <Title>The Problem.</Title>
        <Row className="text-center">
          <Col lg="3" sm="6" className="mb-4">
            <Box>
              <Title textColor="primary">
                £2.2 <span style={{ fontSize: "26px" }}>Billion</span>
              </Title>
              <Text mt={[-3, -3, -3, -4]}>
                Total cost of retail crime in 2018/2019.
              </Text>
            </Box>
          </Col>
          <Col lg="3" sm="6" className="mb-4">
            <Box>
              <Title textColor="primary">
                £1 <span style={{ fontSize: "26px" }}>Billion</span>
              </Title>
              <Text mt={[-3, -3, -3, -4]}>Lost to criminals by theft</Text>
            </Box>
          </Col>
          <Col lg="3" sm="6" className="mb-4">
            <Box>
              <Title textColor="primary">30%</Title>
              <Text mt={[-3, -3, -3, -4]}>
                Increase in reported crime since 2013.
              </Text>
            </Box>
          </Col>
          <Col lg="3" sm="6" className="mb-4">
            <Box>
              <Title textColor="primary">1 in 25</Title>
              <Text mt={[-3, -3, -3, -4]}>
                Retail staff experience violent crime at work.
              </Text>
            </Box>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Fact;
