import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgHalf from "../../assets/image/shopwatch/partnership.jpg";

const ImageHalfContainer = styled(Box)`
  position: static;
  right: -260px;
  z-index: -1;
  height: 100%;
  overflow: hidden;

  .img-half {
    height: auto;
    @media ${device.lg} {
      height: 100%;
    }
  }

  @media ${device.lg} {
    position: absolute !important;
    height: 100%;
  }
  @media ${device.xl} {
    position: absolute !important;
    height: 100%;
  }
`;
// right: -500px;

interface ThemeProps {
  theme: DefaultTheme;
}

const Content1 = () => (
  <>
    <div className="overflow-hidden">
      <Container>
        <Row>
          <Col
            md={12}
            lg={6}
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <Section py={[5, null, null, "50px"]}>
              <div className="omga-02__content-text section-title order-lg-1">
                <Title>In partnership.</Title>
                <Text fontSize="24px" fontWeight="700" color="primary">
                  Train
                </Text>
                <Text variant="small" mb={3}>
                  Face-to-Face and group training events, as well as access to
                  our online video training modules, ensures staff are always
                  trained.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">
                  Grow
                </Text>
                <Text variant="small" mb={3}>
                  More users are better for everyone, our travelling ambassadors
                  and dual branded marketing resources are proven to grow your
                  schemes membership.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">
                  Earn
                </Text>
                <Text variant="small" mb={3}>
                  We undertake a full facilities management role to bill, not
                  only our radios, but your partnership fee or radio income too.
                </Text>
                <Text fontSize="24px" fontWeight="700" color="primary">
                  Support
                </Text>
                <Text variant="small" mb={3}>
                  Our 24/7/365 helpdesk is always at hand to identify a problem,
                  with next day service exchange and prepaid return packing keep
                  our members operational.
                </Text>
              </div>
            </Section>
          </Col>
          <Col md={12} lg={5} className="position-relative mb-5 mb-lg-0">
            <ImageHalfContainer>
              <div className="h-100">
                <img src={imgHalf} alt="" className="img-half img-fluid" />
              </div>
            </ImageHalfContainer>
          </Col>
        </Row>
      </Container>
    </div>
  </>
);

export default Content1;
